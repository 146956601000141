/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import "../../../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import 'styles-variables';

@import '../../../node_modules/bootstrap/scss/bootstrap-reboot';
@import '../../../node_modules/bootstrap/scss/bootstrap-grid';
@import 'styles-reset';

//@import '../node_modules/@angular/material/theming';

//@include mat-core();

//@import 'themes/default-theme.scss';
//@import 'themes/light-theme.scss';
//@import 'themes/black-theme.scss';
//@import 'themes/nature-theme.scss';

//@import 'styles-reset.scss-theme';
//@import 'app/app.component.scss-theme';

@import 'themes/sass-partials/_fonts.scss';

html, body {
  font-family: 'Mosk', sans-serif;
}

body {
  margin: 0;
}

.form-header {
  text-align: center;
}

.center {
  text-align: center;
}

.mat-progress-spinner {
  margin: 0 auto;
}

.ta-container {
  //background-color: #eeebe7;
  border: 2px solid #195188;
  border-radius: 15px;
  width: 50%;
  padding: 20px;
  margin: 40px auto;
}

.ta-input {
  background-color: white;
  border: 1px solid #195188;
  border-radius: 4px;
  padding: 5px;
  margin: 5px;
}

.runner-wrapper {
  padding-left: 3rem;
  padding-right: 3rem;
}

.runner-wrapper mat-card {
  width: 75%;
  margin-bottom: 10px;

}

//Temporary work around for date time picker having a transparent background https://github.com/h2qutc/angular-material-components/issues/348
.mat-datepicker-content {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
  background-color: #fff;
  color: #000000de
}

.mat-calendar-body-selected {
  background-color: #007f51;
  color: #fff;
}

.mat-calendar-body-cell-content {
  border: none !important;
}

.mat-mdc-header-cell {
  font-family: 'Mosk', sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #000000de;
}

.mat-mdc-cell {
  font-family: 'Mosk', sans-serif;
  font-size: 15px;
  color: #100b0b;
}

.mdc-button__label {
  font-family: 'Mosk', sans-serif;
  font-size: 15px;
}

.action-icon {
  font-size: 28px!important;
  height: 28px!important;
  width: 28px!important;
  color: #63585c;
}

.answer-container {
  flex-direction: column;
  display: flex;
  place-content: center;
  align-items: center;
}
