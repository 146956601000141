
@font-face {
  font-family: 'GDSTransportWebsite';
  src: local('GDSTransportWebsite'),
  url('../../assets/fonts/GDSTransportWebsite.ttf') format('truetype');
}

@font-face {
  font-family: 'GDSTransportWebsite-Bold';
  font-weight: bold;
  src: local('GDSTransportWebsite-Bold'),
  url('../../assets/fonts/GDSTransportWebsite-Bold.ttf') format('truetype');
}

@font-face {
  font-family: "Mosk";
  font-style: normal;
  font-weight: 300;
  src: local("Mosk"), local("Mosk-light"), url("../../assets/fonts/Mosk Light 300.ttf") format("truetype");
}

@font-face {
  font-family: "Mosk";
  font-style: normal;
  font-weight: 500;
  src: local("Mosk"), local("Mosk-medium"), url("../../assets/fonts/Mosk Medium 500.ttf") format("truetype");
}

@font-face {
  font-family: "Mosk";
  font-style: normal;
  font-weight: 700;
  src: local("Mosk"), local("Mosk-bold"), url("../../assets/fonts/Mosk Bold 700.ttf") format("truetype");
}

.font-family-base {
  font-family: Mosk, sans-serif;
}
